import { defineStore } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';
import { type FullScreenModalConfig } from '~/units/core/types';
import { useAppMode } from '~/composables';

export const useCoreStore = defineStore({
  id: 'core',
  state: (): {
    metaTags: {
      title: string;
    };
    deviceSize: number;
    headerHeight: number;

    isAnyModalVisible: boolean;

    fullScreenModalConfig: FullScreenModalConfig | null,
  } => {
    return {
      metaTags: {
        title: '',
      },
      deviceSize: 0,
      headerHeight: 0,

      isAnyModalVisible: false,
      fullScreenModalConfig: null,
    };
  },
  actions: {
    updateMetaTags(tags: any) {
      this.metaTags = tags;
    },
    updateMetaTag(key: 'title', value: string) {
      this.metaTags[key] = value;
    },

    updateMetaTagForce(key: 'title', value: string) {
      this.updateMetaTag(key, value);
      useSeoMeta({
        title: this.$state.metaTags.title,
      });
    },

    updateMetaTagsGroup(meta: {
      is_noindex?: boolean;
      is_nofollow?: boolean;
      title?: string;
      seo_meta_description?: string;
      seo_meta_title?: string;
      og_meta_description?: string;
      og_meta_title?: string;
      og_meta_image?: string;
      og_meta_twitter_card?:
        | 'summary'
        | 'summary_large_image'
        | 'app'
        | 'player'
        | null
        | undefined;
    }) {
      const websiteStore = useWebsiteStore();

      const noIndexStatus = (() => {
        if (process.env.APP_STAGE !== 'production') {
          return true;
        }

        if (websiteStore.company?.is_noindex) {
          return !!websiteStore.company?.is_noindex;
        }
        return !!meta?.is_noindex;
      })();

      const noFollowStatus = (() => {
        if (process.env.APP_STAGE !== 'production') {
          return true;
        }

        if (websiteStore.company?.is_nofollow) {
          return !!websiteStore.company?.is_nofollow;
        }
        return !!meta?.is_nofollow;
      })();

      const ogTitle = (() => {
        if (meta.og_meta_title) {
          return meta.og_meta_title;
        }
        if (meta.seo_meta_title) {
          return meta.seo_meta_title;
        }
        if (meta.title) {
          return meta.title;
        }
        return '';
      })();

      const ogDescription = (() => {
        if (meta.og_meta_description) {
          return meta.og_meta_description;
        }
        if (meta.seo_meta_description) {
          return meta.seo_meta_description;
        }
        return '';
      })();

      const ogTwitterCard = (() => {
        if (meta.og_meta_twitter_card) {
          return meta.og_meta_twitter_card;
        }
        return 'summary';
      })();

      useSeoMeta({
        title: meta.title,
        ogTitle,
        description: meta.seo_meta_description,
        ogDescription,
        twitterCard: ogTwitterCard,
        ogImage: meta.og_meta_image,
        robots() {
          return {
            index: !noIndexStatus,
            follow: !noFollowStatus,
            noindex: noIndexStatus,
            nofollow: noFollowStatus,
          };
        },
      });
    },

    updateDeviceSize() {
      this.deviceSize = window.innerWidth;
    },

    async shareApi(config: { title?: string; text?: string; url?: string }) {
      try {
        if (navigator && navigator.share) {
          await navigator.share(config);
        } else {
          const app = useNuxtApp();
          const toast = app.vueApp._context.provides.$toast;

          await navigator.clipboard.writeText(config.url);
          toast.open({
            message: 'Link copied to clipboard.',
            type: 'success',
          });
        }
      } catch (e) {
        // console.log(e);
      }
    },

    openTab(link: string, target: '_blank') {
      window.open(link, target);
    },

    setHeaderHeight(size: number) {
      this.headerHeight = size;
    },

    setAnyModalVisibility(newState: boolean = false) {
      this.isAnyModalVisible = newState;
    },
    
    setFullScreenModalConfig(config: null | FullScreenModalConfig) {
      this.fullScreenModalConfig = config;
    },

   async getRecaptchaInstantToken() {
     function handleRecaptcha() {
       return new Promise((resolve, reject) => {
         grecaptcha.ready(() => {
           const runtimeConfig = useRuntimeConfig()

           grecaptcha.execute(runtimeConfig.public.RECAPTCHA_CLIENT_KEY, { action: 'submit' })
             .then(token => {
               resolve(token); // Resolve the promise with the token
             })
             .catch(error => {
               reject(error); // Reject the promise in case of an error
             });
         });
       });
     }

     if (useAppMode().client) {
       const token = await handleRecaptcha();
       return {
         token,
       }
     }
    }
  },
  getters: {
    isSmallDevice(state) {
      return state.deviceSize <= 575;
    },

    isMobileDevice(state) {
      return state.deviceSize <= 1023;
    },

    /**
     * @deprecated
     * @param state
     */
    isMobile(state) {
      return state.deviceSize <= 991;
    },

    isSmallThanDesktop(state) {
      return state.deviceSize <= 1199;
    },

    isAnythingOnTopVisible(state) {
      return state.isAnyModalVisible || (state.fullScreenModalConfig && state.fullScreenModalConfig.is_active);
    }
  },
});
